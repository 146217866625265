.konami-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .konami-content {
    position: relative;
    background-color: var(--light-pinkish-white);
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 12px;
    height: 12px;
    background: none;
    border: none;
    border-radius: 4px;
    color: var(--dark-purple);
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; 
  }
  
  .close-button:hover {
    color: var(--soft-pink);
  }
  
  .giphy-embed {
    max-width: calc(100% - 24px);
    border-radius: 4px;
  }
  