.true-false-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.question-container {
    border-radius: 16px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 20px; 
}

.true-button, .false-button, .play-again-button {
    padding: 10px 20px;
    width: 5rem;
    margin: 0 10px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: transform 0.1s ease, background-color 0.1s ease;
}

.true-button {
    background-color: var(--muted-green);
}

.false-button {
    background-color: var(--muted-red);
}

.play-again-button {
    width: 10rem;
    background-color: var(--light-yellow);
}

.true-button:active, .false-button:active, .play-again-button:active {
    transform: scale(0.95); 
    background-color: darken(var(--light-yellow), 10%);
}

.button-container {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
}

.shake {
    animation: shake 0.5s ease;
}

@keyframes glow {
    0% { transform: scale(1); box-shadow: 0 0 10px rgba(255, 255, 255, 0); }
    50% { transform: scale(1.1); box-shadow: 0 0 20px rgba(255, 255, 255, 0.8); }
    100% { transform: scale(1); box-shadow: 0 0 10px rgba(255, 255, 255, 0); }
}

.correct {
    animation: glow 0.5s ease;
}