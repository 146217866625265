:root {
  --dark-purple: #300F2C;
  --light-pinkish-white: #FEF3F5;
  --light-pink: #FBE3E7;
  --soft-pink: #FAD1DD;
  --soft-peach: #F9BDBE;
  --light-yellow: #F9D376;
  --soft-yellow: #F9E293;
  --muted-green: #A8D5BA;
  --muted-red: #D77A88;
}


body, #root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background-color: var(--light-pinkish-white);
  color: var(--dark-purple);
}

main {
  flex-grow: 1;
}